import makeStyles from '@mui/styles/makeStyles';

export const useDataTableHeaderColumns = makeStyles(theme => ({
    tableCell: {
        padding: theme.spacing(2),
        color: theme.palette.v2.light.text.disabled,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: 600,
        textTransform: 'uppercase',
    },
}));
