import {combineReducers} from 'redux';
import {AnimationActionTypes} from './animations.action-type';
import {createReducer} from '../../../utils/create-reducer';
import {ANIMATION_TYPE} from '../utils/constants';

export const animationsReducer = combineReducers({
    isAnimationActive: createReducer(true, AnimationActionTypes.SET_IS_ANIMATION_ACTIVE),
    isSubAnimationActive: createReducer(false, AnimationActionTypes.SET_IS_SUB_ANIMATION_ACTIVE),
    nextDispatch: createReducer(null, AnimationActionTypes.STORE_NEXT_DISPATCH),
    isMobile: createReducer(false, AnimationActionTypes.SET_IS_MOBILE),
    isAnimationInProgress: (state = true, action) => {
        switch (action.type) {
            case AnimationActionTypes.SET_IS_ANIMATION_ACTIVE:
                return action.payload;

            case AnimationActionTypes.ANIMATION_ENDED:
                if (action.payload === ANIMATION_TYPE.SECONDARY) {
                    return false;
                }
                return state;

            default:
                return state;
        }
    },
});
