import {Box, CircularProgress, Skeleton, Typography} from '@mui/material';
import {Helmet} from 'react-helmet';
import {useSelector} from 'react-redux';
import {useRoutes} from 'react-router-dom';
import {LoadingTypes, useLoadingState} from './features/loading';
import {NotificationsContainer} from './features/notifications/components/NotificationProvider';
import {LoggedInUserSelectors} from './features/user/modules/logged-in-user';
import {AppLayout} from './layout/AppLayout/AppLayout';
import {Routes} from './lib/router/routes';
import ScrollToTop from './utils/scroll-to-top';
import {UserRoles, isUserCareOrFreelancer} from './utils/user-roles';
import AppContent from './v1/components/app/AppContent/AppContent';
import ErrorBoundary from './v1/components/app/ErrorBoundary';
import './assets/fonts/avantt/avantt.css';

const RouteElements = () => {
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);

    return useRoutes([
        ...Routes.PublicRoutes,
        ...(isUserCareOrFreelancer(loggedInUser) ? Routes.FreelancerRoutes(loggedInUser) : []),
        ...(loggedInUser?.role === UserRoles.ADMINISTRATOR
            ? [
                ...Routes.AdministratorRoutes,
                ...Routes.DepositOfAccountsRoutes,
            ]
            : []
        ),
    ]);
};

export const App = () => {
    const isLoadingAppData = useLoadingState(LoadingTypes.APPLICATION_DATA);

    if (isLoadingAppData) {
        return (
            <AppLayout
                suspenseFallback={(
                    <>
                        <Skeleton sx={{transform: 'none'}} height={39} width={277}>
                            <Typography variant="h3" />
                        </Skeleton>

                        <Box
                            mt={3}
                            minHeight={320}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={4}
                            backgroundColor="#fff"
                            boxShadow="0 1px 1px 0 rgb(11 35 51 / 5%), 0 1px 1px -1px rgb(11 35 51 / 5%), 0 1px 3px 0 rgb(11 35 51 / 17%)"
                        >
                            <CircularProgress />
                        </Box>
                    </>
                )}
            />
        );
    }

    return (
        <>
            <Helmet>
                <title>HIWAY Platform</title>
            </Helmet>
            <ScrollToTop />
            <NotificationsContainer />
            <ErrorBoundary>
                <AppContent>
                    <RouteElements />
                </AppContent>
            </ErrorBoundary>
        </>
    );
};
