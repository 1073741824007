import {
    faBarsProgress,
    faBolt,
    faCar,
    faChartSimple,
    faCircleQuestion,
    faCreditCard,
    faEuroSign,
    faFileArrowDown,
    faFileContract,
    faFileLines,
    faFolderOpen,
    faGraduationCap,
    faHourglassHalf,
    faMoneyBillTransfer,
    faPlus,
    faReceipt,
    faSackDollar,
    faUser,
    faUsers,
    faWallet,
} from '@fortawesome/free-solid-svg-icons';
import {RoutePaths} from '../../../lib/router/route-paths';
import {UserRoles} from '../../../utils/user-roles';
import {getConfig} from '../../../v1/config';
import {resolveRoute} from '../../../v1/navigation/resolveRoute';
import {ROUTE_PATHS} from '../../../v1/navigation/routePaths';
import {BackofficeActions} from '../../backoffice/store/backoffice.action';

export const SIDEBAR_ROLE_LS_KEY = 'sidebar-role-ls-key';

export const SidebarItemActionType = {
    Link: 'SidebarItemActionType/LINK',
    DispatchAction: 'SidebarItemActionType/DISPATCH_ACTION',
};

export const SidebarButtonActionType = {
    Link: 'SidebarButtonActionType/LINK',
    DispatchAction: 'SidebarButtonActionType/DISPATCH_ACTION',
};

export const AdministratorSidebarNavigation = [
    {
        id: 'list/user-management',
        listTitle: 'administratorSidebar.userManagementTitle',
        listItems: [
            {
                id: 'item/user-management',
                actionType: SidebarItemActionType.Link,
                path: '/',
                icon: faUser,
                title: 'administratorSidebar.userManagementItemTitle',
                dataCy: 'sidebar-users',
                actionButton: {
                    icon: faPlus,
                    requiredRoles: UserRoles.ADMINISTRATOR,
                    actionType: SidebarButtonActionType.Link,
                    path: RoutePaths.CREATE_USER,
                    dataCy: 'sidebar-create-user',
                },
            },
        ],
    },
    {
        id: 'list/company-management',
        listTitle: 'administratorSidebar.companyManagementTitle',
        listItems: [
            // {
            //     id: 'item/coaching',
            //     path: RoutePaths.COACHING,
            //     icon: faGraduationCap,
            //     title: 'administratorSidebar.coachingItemTitle',
            // },
            {
                id: 'item/onboarding-companies',
                actionType: SidebarItemActionType.Link,
                path: RoutePaths.ONBOARDING_COMPANIES,
                icon: faHourglassHalf,
                title: 'administratorSidebar.onboardingCompaniesItemTitle',
                dataCy: 'sidebar-in-creation',
            },
            {
                id: 'item/active-companies',
                actionType: SidebarItemActionType.Link,
                path: RoutePaths.COMPANIES,
                icon: faBolt,
                title: 'administratorSidebar.activeCompaniesItemTitle',
                dataCy: 'sidebar-active',
            },
            {
                id: 'item/formalities',
                actionType: SidebarItemActionType.Link,
                path: RoutePaths.FORMALITIES,
                icon: faFileContract,
                title: 'administratorSidebar.formalities',
                dataCy: 'sidebar-active',
            },
        ],
    },
    {
        id: 'list/user-coaching',
        listTitle: 'administratorSidebar.coachingTitle',
        listItems: [
            {
                id: 'item/cluster',
                actionType: SidebarItemActionType.Link,
                path: getConfig().ROUTE_PATHS.CLUSTER,
                icon: faUsers,
                title: 'common:sideMenu.cluster',
            },
            {
                id: 'item/revenue-simulator',
                icon: faSackDollar,
                title: 'administratorSidebar.revenueSimulatorItemTitle',
                dataCy: 'sidebar-revenue-simulator',
                actionType: SidebarItemActionType.Link,
                path: RoutePaths.REVENUE_SIMULATIONS_LIST,
            },
        ],
    },
    {
        id: 'list/user-supervision',
        listTitle: 'administratorSidebar.supervisionTitle',
        listItems: [
            {
                id: 'item/supervision',
                icon: faFileArrowDown,
                title: 'administratorSidebar.supervisionItemTitle',
                dataCy: 'sidebar-global-data-export',
                actionType: SidebarItemActionType.DispatchAction,
                action: BackofficeActions.globalDataExport,
            },
        ],
    },
];

const AdministratorSidebarCareNavigation = [...AdministratorSidebarNavigation];
AdministratorSidebarCareNavigation.splice(2, 1);
export {AdministratorSidebarCareNavigation};

export const freelancerSidebar = ({
    companyId,
    hasTrainingMenu,
    hasBankAccess,
    numberOfIntegrations,
    showRibAndDocumentsItem,
    isUserCareRole,
}) => {
    let bankMenu;

    if (!hasBankAccess || numberOfIntegrations === 0) {
        // TODO Bridge needed?
        // let path = hasBankAccess
        //     ? getConfig().ROUTE_PATHS.BANK_TRANSACTION_LIST
        //     : getConfig().ROUTE_PATHS.REQUEST_HIWAY_PRO_ACCESS_LP;
        // if (bankIntegrationData?.onboardingStatus === BANK_ONBOARDING_STATUS.INVALID) {
        //     path = getConfig().ROUTE_PATHS.BANK_CREATION_MISSING_INFORMATION;
        // } else if (bankIntegrationData?.onboardingStatus === BANK_ONBOARDING_STATUS.FINALIZED) {
        //     path = getConfig().ROUTE_PATHS.BANK_TRANSACTION_LIST;
        // } else if (bankIntegrationData?.onboardingStatus === BANK_ONBOARDING_STATUS.VALID) {
        //     path = getConfig().ROUTE_PATHS.BANK_CREATION_FINALIZE;
        // }

        bankMenu = [{
            id: 'item/bank-transactions',
            actionType: SidebarItemActionType.Link,
            path: getConfig().ROUTE_PATHS.BANK_TRANSACTION_LIST,
            title: 'common:sideMenu.bank',
            icon: faWallet,
        }];
    }
    if (hasBankAccess && (numberOfIntegrations > 0)) {
        bankMenu = [{
            id: 'item/bank',
            icon: faWallet,
            title: 'common:sideMenu.bank',
            subItems: [
                {
                    id: 'item/BANK_TRANSACTION_LIST',
                    actionType: SidebarItemActionType.Link,
                    path: getConfig().ROUTE_PATHS.BANK_TRANSACTION_LIST,
                    title: 'common:sideMenu.bankItems.transactions',
                    icon: faMoneyBillTransfer,
                },
                {
                    id: 'item/BANK_TRANSFER_LIST',
                    actionType: SidebarItemActionType.Link,
                    path: getConfig().ROUTE_PATHS.BANK_TRANSFER_LIST,
                    title: 'common:sideMenu.bankItems.transfers',
                    icon: faBarsProgress,
                },
                {
                    id: 'item/BANK_CARDS',
                    actionType: SidebarItemActionType.Link,
                    path: getConfig().ROUTE_PATHS.BANK_CARDS,
                    title: 'common:sideMenu.bankItems.cards',
                    icon: faCreditCard,
                },
                ...(
                    showRibAndDocumentsItem
                        ? [{
                            id: 'item/BANK_RIB_AND_DOCUMENTS',
                            actionType: SidebarItemActionType.Link,
                            path: getConfig().ROUTE_PATHS.BANK_RIB_AND_DOCUMENTS,
                            title: 'common:sideMenu.bankItems.ribAndDocuments',
                            icon: faFolderOpen,
                        }]
                        : []
                ),
            ],
        }];
    }

    return [
        {
            id: 'list/navigationCompanies',
            listTitle: 'common:sideMenu.companyManagementHeader',
            listItems: [
                {
                    id: 'item/dashboard',
                    actionType: SidebarItemActionType.Link,
                    path: getConfig().ROUTE_PATHS.DASHBOARD,
                    icon: faChartSimple,
                    title: 'common:sideMenu.dashboard',
                },
                ...(bankMenu || []),
                ...(!isUserCareRole
                    ? [{
                        id: 'item/invoicing',
                        actionType: SidebarItemActionType.Link,
                        path: getConfig().ROUTE_PATHS.INVOICING,
                        icon: faEuroSign,
                        title: 'common:sideMenu.invoicing',
                    }]
                    : []),
                {
                    id: 'item/expenses',
                    path: getConfig().ROUTE_PATHS.PERSONAL_EXPENSES,
                    icon: faReceipt,
                    title: 'common:sideMenu.expenses',
                    subItems: [
                        ...(!isUserCareRole ? [{
                            id: 'item/personal-expenses',
                            actionType: SidebarItemActionType.Link,
                            path: getConfig().ROUTE_PATHS.PERSONAL_EXPENSES,
                            title: 'common:sideMenu.expenseItems.personal',
                            icon: faCreditCard,
                        }] : []),
                        {
                            id: 'item/business-kilometers-allowance',
                            actionType: SidebarItemActionType.Link,
                            path: getConfig().ROUTE_PATHS.BUSINESS_KILOMETERS_ALLOWANCE,
                            title: 'common:sideMenu.expenseItems.businessKMAllowance',
                            icon: faCar,
                        },
                    ],
                },
                ...(!isUserCareRole ? [{
                    id: 'item/documentation',
                    actionType: SidebarItemActionType.Link,
                    path: getConfig().ROUTE_PATHS.DOCUMENTATION,
                    icon: faCircleQuestion,
                    title: 'common:sideMenu.documentation',
                }] : []),
                ...(companyId ? [{
                    id: 'item/documents',
                    actionType: SidebarItemActionType.Link,
                    path: resolveRoute(ROUTE_PATHS.DOCUMENTS, {companyId}),
                    icon: faFileLines,
                    title: 'common:sideMenu.documentsDatabase',
                }] : []),
            ],
        },
        ...(!isUserCareRole ? [{
            id: 'list/navigationServices',
            listTitle: 'common:sideMenu.servicesHeader',
            listItems: [{
                id: 'item/cluster',
                actionType: SidebarItemActionType.Link,
                path: getConfig().ROUTE_PATHS.CLUSTER,
                icon: faUsers,
                title: 'common:sideMenu.cluster',
            },
            ...(hasTrainingMenu ? [{
                id: 'item/training',
                actionType: SidebarItemActionType.Link,
                path: getConfig().ROUTE_PATHS.TRAINING,
                icon: faGraduationCap,
                title: 'common:sideMenu.training',
                hasBadge: true,
            }] : [])],
        }] : []),
        ...(!isUserCareRole ? [{
            id: 'list/navigationWealthManagement',
            listTitle: 'common:sideMenu.prepareForFutureHeader',
            listItems: [
                {
                    id: 'item/wealth',
                    actionType: SidebarItemActionType.Link,
                    path: getConfig().ROUTE_PATHS.WEALTH_MANAGEMENT,
                    icon: faSackDollar,
                    title: 'common:sideMenu.wealth',
                },
            ],
        }] : []),
    ];
};
