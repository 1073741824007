import {Paper, Tab, Tabs} from '@mui/material';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {FORMALITIES_TABS} from '../../features/company-profile/modules/formalities/utils/constants';
import {importLocaleBundle} from '../../lib/i18next';
import {replace, selectRouterLocation} from '../../lib/router/connected-router-saga';
import {RoutePaths} from '../../lib/router/route-paths';
import PageHeader from '../../v1/components/app/PageHeader/PageHeader';
import {useDataTableTabFiltersStyles} from '../../v1/components/ui-kit/DataTableTabFilters/styles';

// TODO: HPD-5014 - uncomment after BE be ready
// importLocaleBundle('vatDeclaration');
importLocaleBundle('financialStatements');

export const FormalitiesScreen = () => {
    const {t: tSidebar} = useTranslation('sidebar');
    // const {t: tVatDeclaration} = useTranslation('vatDeclaration');
    const {t: tFinancialStatements} = useTranslation('financialStatements');

    const location = useSelector(selectRouterLocation);
    const [activeTab, setActiveTab] = useState(location.pathname);
    const styles = useDataTableTabFiltersStyles();
    const dispatch = useDispatch();

    const handleChange = (_, newValue) => {
        setActiveTab(newValue);
        if (activeTab !== newValue) {
            dispatch(replace(newValue));
        }
    };

    useEffect(() => {
        setActiveTab(location.pathname);
    }, [location.pathname]);

    return (
        <>
            <PageHeader title={tSidebar('administratorSidebar.formalities')} />
            <Paper elevation={2} sx={{borderRadius: '16px', pb: 3}}>
                <Tabs value={activeTab} onChange={handleChange} className={styles.tabs}>
                    <Tab
                        className={styles.tab}
                        label={tFinancialStatements('title')}
                        value={RoutePaths.FINANCIAL_STATEMENTS}
                    />
                    <Tab
                        label={tFinancialStatements(`companies:formalities.tabs.${FORMALITIES_TABS.VAT_DECLARATION}`)}
                        disabled
                    />
                    <Tab
                        label={tFinancialStatements(`companies:formalities.tabs.${FORMALITIES_TABS.OFFICE_CHANGE}`)}
                        disabled
                    />
                </Tabs>
                <Outlet />
            </Paper>
        </>
    );
};
