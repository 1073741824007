const selectIsAnimationActive = state => state.animations.isAnimationActive;
const selectIsSubAnimationActive = state => state.animations.isSubAnimationActive;
const selectNextDispatch = state => state.animations.nextDispatch;
const selectIsMobile = state => state.animations.isMobile;
const selectIsinProgress = state => state.animations.isAnimationInProgress;

export const AnimationsSelectors = {
    selectIsAnimationActive,
    selectIsSubAnimationActive,
    selectNextDispatch,
    selectIsMobile,
    selectIsinProgress,
};
