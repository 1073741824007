import makeStyles from '@mui/styles/makeStyles';

export const useDataTableStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(3),
    },
    tableCell: {
        padding: theme.spacing(1, 2, 0),
    },
    contentTableCell: {
        padding: theme.spacing(1, 2),
    },
    preserveWhiteSpace: {
        whiteSpace: 'pre',
    },
    textWrap: {
        overflowWrap: 'anywhere',
    },
    tableContainer: {
        marginTop: theme.spacing(3),
    },
    tableRow: {
        '&.MuiTableRow-hover:hover': {
            backgroundColor: theme.palette.v2.light.primary.shades,
        },
        '&:hover a': {
            color: theme.palette.primary.main,
        },
    },
}));
