import {TableFooter, TablePagination, TableRow} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import {useTranslation} from 'react-i18next';
import {useDataTableFooterStyles} from './styles';

const DataTableFooter = ({rowsPerPage, page, onPageChange, onRowsPerPageChange, count, rowsPerPageOptions}) => {
    const {t} = useTranslation('common');

    const styles = useDataTableFooterStyles();

    return (
        <TableFooter className={styles.root}>
            <TableRow>
                <TablePagination
                    className={styles.pagination}
                    count={count}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    labelRowsPerPage={t('pagination.rowsPerPage')}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            </TableRow>
        </TableFooter>
    );
};

DataTableFooter.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

DataTableFooter.defaultProps = {
    rowsPerPageOptions: [10, 25, 50],
};

export default DataTableFooter;
